<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white">
        <div>
          <extDoogDoor></extDoogDoor>
        </div>
        <div class="mt-8">
          <edd-sales-pie-chart></edd-sales-pie-chart>
        </div>
        <div class="mt-8">
          <geography-chart></geography-chart>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import GeographyChart from './geographyChart.vue'
import EddSalesPieChart from './eddSalesPieChart.vue'
import extDoogDoor from './extDogDoor.vue'
export default {
  components: {
    extDoogDoor,
    EddSalesPieChart,
    GeographyChart
},
  data () {
    return {
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    document.title = 'Consumer Sales ◾ Ext. Dog Door'
  },
  watch: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>
