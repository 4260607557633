<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-10' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <p>Extreme Dog Door Sales</p>
            <div class="flex">
            </div>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" >
              <button id="toggle" class="bg-gray-600 hover:bg-gray-700 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div style="overflow-y: auto; white-space: nowrap;overflow-x: hidden;" class="table_containder" v-if="ExtFListingObj.length > 0 && firstLoading">
            <div :style="`max-height:` +  cardHight + `px; !important`" class="pt-2">
              <div id="chart">
                <apexchart type="line" height="650" :options="chartOptions" :series="series"></apexchart>
              </div>
            </div>
          </div>
          <div class="layout m-2" v-if="ExtFListingObj.length === 0 && firstLoading">
            <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <div>
                <p class=" font-medium heading-4">No record found.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box col-span-2 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" :style="!isFilterOpen ? 'display: none' : ''">
          <GlobalFilter :callFrom="'extremeDogDoor'" :callParent="'Sales'"  @closeFilter="closeFilter" :isFilterOpen="isFilterOpen"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import API from '@/api/App.js'
import GlobalFilter from '@/View/Admin/Components/filterWithoutSelection.vue'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    GlobalFilter,
  },
  data () {
    return {
      series: [],
      chartOptions: {
        animations: {
          enabled: false
        },
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            autoSelected: 'zoom'
          },
          dataLabels: {
            enabled: false
          },
        },
        stroke: {
          width: [5,5,4],
          curve: 'straight'
        },
        tooltip: {
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            console.log('series', series)
            console.log('seriesIndex', seriesIndex)
            console.log('dataPointIndex', dataPointIndex)
            console.log('w', w)
            return '<ul style="padding:4px;">' +
            '<li><b>' + w.config.series[seriesIndex].name + '</b>: $' + w.config.series[seriesIndex].data[dataPointIndex].toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '</li>' +
            '</ul>';
          }
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          title: {
            text: 'Month/Year'
          },
          tickPlacement: 'on',
          categories: [],
          group: {
            style: {
              fontSize: '10px',
              fontWeight: 700
            },
            groups: [
            ]
          },
          labels: {
            formatter: function (val) {
              return val + ""
            }
          },
        },
        yaxis: {
          title: {
            text: 'Sales(USD)'
          },
          labels: {
            formatter: function (value) {
              return `$${value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
            }
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
      isFilterOpen: true,
      ExtFListingObj: [],
      filterObject: {
        startDate: '',
        endDate: '',
        minDate: '',
        maxDate: '',
      },
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      firstLoading: false,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    this.cardHight = window.innerHeight - 171
    this.mainHeight = window.innerHeight - 90
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    this.filterObject.startDate = this.defaultStartDate
    this.filterObject.endDate = this.defaultEndDate
  },
  watch: {
    filterObject: {
      handler () {
        console.log('filterObject', this.filterObject)
      },
      deep: true
    },
  },
  methods: {
    closeFilter (data) {
      console.log('data', data)
      this.filterObject.startDate = data.startDate
      this.filterObject.endDate = data.endDate
      this.getListing()
    },
    openCloseFilter () {
      if (this.isFilterOpen) {
        this.isFilterOpen = false
      } else {
        this.isFilterOpen = true
      }
      console.log('openCloseFilter')
    },
    getListing () {
      this.firstLoading = false
      console.log('this.filterObject.startDate 123 123', this.filterObject.startDate)
      console.log('this.filterObject.endDate 123 123', this.filterObject.endDate)
      let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      API.ExtremeDogDoorConsumerSales(
        utcSTART,
        utcEND,
        false,
        response => {
          this.firstLoading = true
          let ExtFListingObj = response.Data === null ? [] : response.Data
          let TempMonth = ['January','February','March','April','May','June','July','August','September','October','November','December']
          for (let index = 0; index < ExtFListingObj.length; index++) {
            const element = ExtFListingObj[index].yearDetail
            element.sort(function(a, b){
                return TempMonth.indexOf(a.months)
                - TempMonth.indexOf(b.months);
              });
            console.log('ExtFListingObj 2', ExtFListingObj)
          }
          this.ExtFListingObj = ExtFListingObj
          this.addFile()
          for (let I = 0; I < this.ExtFListingObj.length; I++) {
            for (let JJ = 0; JJ < this.ExtFListingObj[I].yearDetail.length; JJ++) {
              let Ele = this.ExtFListingObj[I].yearDetail[JJ]
              this.chartOptions.xaxis.categories.push(`${Ele.months} ${this.ExtFListingObj[I].year}`)
            }
          }
          for (let I = 0; I < this.ExtFListingObj.length; I++) {
            for (let JJ = 0; JJ < this.ExtFListingObj[I].yearDetail.length; JJ++) {
              console.log(' 123 123this.ExtFListingObj[I].yearDetail--------------------------', this.ExtFListingObj[I].yearDetail.length)
              console.log(' 123 123JJ----------------------------', JJ + 1)
              if (this.ExtFListingObj.length > 0) {
                if (this.ExtFListingObj[I].yearDetail.length === JJ + 1) {
                  this.series[0].data.push(null)
                } else {
                  let Ele = this.ExtFListingObj[I].yearDetail[JJ]
                  this.series[0].data.push(Ele.orderTotalValue)
                }
              } else {
                let Ele = this.ExtFListingObj[I].yearDetail[JJ]
                this.series[0].data.push(Ele.orderTotalValue)
              }
            }
          }
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
        },
        error => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    addFile () {
      this.series = []
      for (let index = 0; index < this.ExtFListingObj.length; index++) {
        this.chartOptions.xaxis.group.groups.push({
          title: this.ExtFListingObj[index].year,
          cols: this.ExtFListingObj[index].yearDetail.length
        })
      }
      console.log('this.chartOptions.xaxis.group.groups', this.chartOptions.xaxis.group.groups)
      let chartTitle = ['Order Total Value']
      for (let index = 0; index < chartTitle.length; index++) {
        this.series.push({
          name: chartTitle[index],
          data: [],
        })
      }
    }
  }
}
</script>
<style scoped>

.xsmall_col {
  /* min-width: 54px !important; */
  /* width: 54px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.dates_col {
  /* min-width: 120px !important; */
  /* width: 120px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.total_col {
  /* min-width: 82px !important; */
  /* width: 82px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.vsmall_col {
  /* min-width: 94px !important; */
  /* width: 94px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.avgSell_col {
  /* min-width: 133px !important; */
  /* width: 133px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.avgSell2_col {
  /* min-width: 138px !important; */
  /* width: 138px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.mid_col {
  /* min-width: 180px !important; */
  /* width: 180px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  /* min-width: 84px !important; */
  /* width: 84px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.large_col {
  /* min-width: 260px !important; */
  /* width: 260px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.desc_col {
  /* min-width: 240px !important; */
  /* width: 240px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

</style>
