<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-10' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <p>ExtremeDogDoor.com Sales by Geography</p>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" >
              <button id="toggle" class="bg-gray-600 hover:bg-gray-700 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div class="">
            <l-map :options="{preferCanvas: true}" ref="geojsonelem"
              :zoom="zoom"
              :center="center"
              style="min-height: 88vh;width: 100%"
              >
              <l-tile-layer
                :url="url"
              />
              <l-geo-json
                v-for="(data, index) in listing" :key="index"
                :geojson="data.data"
                :options="options"
                :options-style="styleFunction"
                  />
            </l-map>
          </div>
        </div>
        <div class="box col-span-2 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" id="mainDiv" v-if="isFilterOpen">
          <div class="bg-white">
            <div class=" bg-primary2 p-2 items-center justify-between flex h-11 sticky top-0">
              <div class="heading-4 text-white font-bold">Filter</div>
              <span class="centerLine">
                <div class=" text-white cursor-pointer hover:underline mr-2" @click="resetAll()">
                  <button style="letter-spacing: 1px;" class="border border-gray1 px-3 py-1 cust_card heading-7">Reset</button>
                </div>
                <div class=" text-white cursor-pointer hover:underline" @click="applyFilter()">
                  <button style="letter-spacing: 1px;" class="border border-gray1 px-3 py-1 cust_card heading-7">Apply</button>
                </div>
              </span>
            </div> 
            <div class="pt-1">
              <div v-if="filterObject.startDate !== '' || filterObject.endDate !== ''">
                <p class="text-text1 heading-6 font-bold pl-2">
                  <span class="text-text2 heading-6 font-bold">{{filterObject.startDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-text2 heading-6 font-bold">{{filterObject.endDate | DMMYYYYddd}}</span>
                </p>
              </div>
              <div class="pt-1 pl-1">
                <button style="letter-spacing: 1px"
                v-for="(data, index) in buttonFiltersArray" :key="index"
                  @click="getDateTimeForFilterHandler(index, true)"
                  :class="data.selected ? 'text-white bg-primary2' : 'text-primary2 bg-white hover:bg-blue-50'"
                  class="m-1 px-1 py-0.5 heading-7 rounded-md border border-primary2"
                  >{{data.title}}</button
                >
              </div>
            </div>
            <div class="divider m-1"></div>
            <div class="p-2 my-1 cust_card group" >
              <div class="centerLine justify-between ">
                <div class="centerLine">
                  <span class=" text-text2 heading-6 font-bold">State</span>
                  <div class="loader2 ease-linear ml-2" v-if="isOrderReqLoading"></div>
                </div>
                <div class="centerLine  pt-2 pb-1">
                  <div class="pr-2 cursor-pointer" @click="showOrderReqLoader = !showOrderReqLoader">
                    <span v-if="!showOrderReqLoader"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                    <p v-if="showOrderReqLoader"><i class="fas fa-times h-4 w-4 text-gray4"></i></p>
                  </div>
                  <div class="pr-2 ">
                    <button  @click="resetOrderReq()" style="letter-spacing: 0.5px;" class="border border-gray-400 rounded-md px-2 heading-7 cursor-pointer">Clear</button>
                  </div>
                  <span @click="selectedAll('itemNameList')" class="centerLine cursor-pointer" v-if="parentOrderReqList.length > 0">
                    <input type="checkbox" class="w-4 h-4 cursor-pointer" v-model="orderReqSelectAll" >
                    <div class="text-text1 pl-1 heading-6 cursor-pointer">
                      {{!orderReqSelectAll ? 'All' : 'Null'}}
                    </div>
                  </span>
                </div>
              </div>
              <div style="display:flex" class=" text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-7" v-if="showOrderReqLoader">
                <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="Item Name Search" id="custSearchInput2"
                  v-model="orderReqSearch"
                >
                <span v-if="orderReqSearch.length > 0" class="cursor-pointer"  @click="clearOrderReq()">
                  <i class="fas fa-times h-4 w-4 text-gray4"></i>
                </span>
              </div>
              <div class="overflow-auto" style="max-height: 280px !important;" v-if="parentOrderReqList.length > 0">
                <div v-for="(data, index) in parentOrderReqList" :key="index">
                  <div class="centerLine" @click="selectData(data, index, 'itemNameList')">
                    <input type="checkbox" class="h-4 w-4 cursor-pointer" v-model="data.isSelected" >
                    <p class=" text-text1 pl-1 heading-6 cursor-pointer overFlowParaA w-96">{{data.displayName}}</p>
                  </div>
                </div>
              </div>
              <div class="layout" v-if="parentOrderReqList.length === 0 && isRefresh">
                <div class="flex p-1 text-sm text-text1 bg-gray2 rounded items-center" role="alert">
                  <div>
                    <p class=" font-medium heading-5">No record found.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white container" v-if="showCustomDatepicker" >
      <div class="popup_overlay" style="z-index: 100000000">
        <CustomDateSelector :appliedFilters="filterObject" @closeSelectedDialog="customDateListener" v-if="showCustomDatepicker"/>
      </div>
    </div>
</div>
</template>
<script>
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LGeoJson } from 'vue2-leaflet'
import dataBase from '@/View/components/us-states.json'
import deboucneMixin from '@/mixins/debounce.js'
import moment from 'moment'
import API from '@/api/App.js'
import CustomDateSelector from '@/View/components/customDateSelection'
import {getDayAccordingToLimits} from '@/utils/convertDateAndTime.js'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    CustomDateSelector,
    LMap,
    LTileLayer,
    LGeoJson,
  },
  mixins: [deboucneMixin],
  data () {
    return {
      listing: [],
      zoom: 4,
      center: [40.906341, -98.071043],
      geojson: null,
      fillColor: '#e4ce7f',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      marker: latLng(47.41322, -1.219482),
      showOrderReqLoader: false,
      tempItemNameList: [],
      orderSourceSelectAll: false,
      orderReqSelectAll: false,
      totalCount: 0,
      isFilterOpen: false,
      isRefresh: true,
      searchVal: '',
      orderReqSearch: '',
      searchLoader: false,
      ExtFListingObj: [],
      selectAllCheck: false,
      parentOrderReqList: [],
      requiredDataListing: [],
      getListingDetail3: () => {},
      isOrderReqLoading: false,
      isZipLoading: false,
      isPaymentLoading: false,
      pagination: {
        sortBy: 'orderShippingTotalCost',
        descending: true
      },
      buttonFiltersArray: [
        {
          title: 'Today',
          selected: false,
          value: 'today'
        },
        {
          title: 'Yesterday',
          selected: false,
          value: 'yesterday'
        },
        {
          title: 'Last Week',
          selected: false,
          value: 'lastWeek'
        },
        {
          title: 'Last 7 Days',
          selected: false,
          value: 'thisWeek'
        },
        {
          title: 'Last Month',
          selected: false,
          value: 'lastMonth'
        },
        {
          title: 'Last 30 Days',
          selected: true,
          value: 'last30Days'
        },
        {
          title: 'Last 60 Days',
          selected: false,
          value: 'last60Days'
        },
        {
          title: 'Last 90 Days',
          selected: false,
          value: 'last90Days'
        },
        {
          title: 'Last 120 Days',
          selected: false,
          value: 'last120Days'
        },
        {
          title: 'Custom',
          selected: false,
          value: 'custom'
        }
      ],
      selectedValue: 'last30Days',
      filterObject: {
        itemNameList: [],
        startDate: '',
        endDate: '',
        minDate: '',
        maxDate: '',
      },
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      showCustomDatepicker: false,
      firstLoading: false,
      stateArray: dataBase,
      tempData: []
    }
  },
  computed: {
    options () {
      return {
        onEachFeature: this.onEachFeatureFunction
      }
    },
    styleFunction () {
      return (feature) => {
        return {
          weight: 0.6,
          color: '#000000',
          opacity: 1,
          fillColor: feature.properties.color,
          fillOpacity: 0.8
        }
      }
    },
    onEachFeatureFunction () {
      return (feature, layer) => {
        layer.bindTooltip(
          '<div>Shipping State:' +'<span style="padding-left: 8px;">' + feature.properties.inShort +'</span></div>'+
          '<div>Total Orders:' +'<span style="padding-left: 8px;">' + feature.properties.totalOrder +'</span></div>'+
          '<div>Total Revenue:' +'<span style="padding-left: 8px;">$' + this.format(feature.properties.value)+'K</span></div>',
          { permanent: false, sticky: true }
        )
      }
    }
  },
  async created () {
  },
  mounted () {
    setTimeout(function () { window.dispatchEvent(new Event('resize')) }, 250)
    this.getListingDetail3 = this.debounce(function () {
      this.getItemList(false)
    }, 500)
    this.cardHight = window.innerHeight - 171
    this.mainHeight = window.innerHeight - 90
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    this.filterObject.startDate = this.defaultStartDate
    this.filterObject.endDate = this.defaultEndDate
    this.apiCaller()
    this.getListing()
  },
  watch: {
    orderReqSearch: {
      handler() {
        this.getListingDetail3()
      }
    },
    tempItemNameList: {
      handler () {
        console.log('tempItemNameList', this.tempItemNameList)
        this.filterObject.itemNameList = []
        this.tempItemNameList.forEach(a => {
          if (a.displayName !== '') {
            this.filterObject.itemNameList.push(a.displayName)
          }
        })
      },
      deep: true
    },
    filterObject: {
      handler () {
        console.log('filterObject', this.filterObject)
      },
      deep: true
    },
  },
  methods: {
    format(num) {
      return (num / 1000).toFixed(2)
    },
    getColor(d) {
      if (this.selectedValue === 'custom') {
        console.log('d', d)
        if(d > 19000){
          return "#0000cc"
        }
        if(d > 16000){
          return "#0000e6"
        }
        if(d > 14000){
          return "#0000ff"
        }
        if(d > 12500){
          return "#1a1aff"
        }
        if(d > 11000){
          return "#3333ff"
        }
        if(d > 9000){
          return "#4d4dff"
        }
        if(d > 8200){
          return "#6666ff"
        }
        if(d > 7600){
          return "#8080ff"
        }
        if(d > 5000){
          return "#9999ff"
        }
        if(d > 3000){
          return "#b3b3ff"
        }
        if(d > 1000){
          return "#ccccff"
        }
        if(d > 0){
          return "#e6e6ff"
        }
        if(d === 0){
          return "#0000000"
        }
      } else {
        if(d > 2100){
          return "#0000cc"
        }
        if(d > 1900){
          return "#0000e6"
        }
        if(d > 1700){
          return "#0000ff"
        }
        if(d > 1400){
          return "#1a1aff"
        }
        if(d > 1100){
          return "#3333ff"
        }
        if(d > 900){
          return "#4d4dff"
        }
        if(d > 700){
          return "#6666ff"
        }
        if(d > 500){
          return "#8080ff"
        }
        if(d > 300){
          return "#9999ff"
        }
        if(d > 150){
          return "#b3b3ff"
        }
        if(d > 50){
          return "#ccccff"
        }
        if(d > 0){
          return "#e6e6ff"
        }
        if(d === 0){
          return "#0000000"
        }
      }
    },
    generateRandomColor () {
      console.log('calll zip')
      // let tempColor = ['#A2CDB0', '#ECCCB2', '#CC9544', '#0F6292', '#B6EAFA', '#1B9C85', '#3C486B', '#FE6244']
      // return tempColor[(Math.floor(Math.random() * tempColor.length))]
      let maxVal = 0xFFFFFF // 16777215
      let randomNumber = Math.random() * maxVal
      randomNumber = Math.floor(randomNumber)
      randomNumber = randomNumber.toString(16)
      let randColor = randomNumber.padStart(6, 0)
      return `#${randColor.toUpperCase()}`
    },
    selectedAll () {
      this.tempItemNameList = []
      if (this.orderReqSelectAll === true) {
        this.orderReqSelectAll = false
      } else {
        this.orderReqSelectAll = true
      }
      if (this.orderReqSearch === '') {
        if (this.orderReqSelectAll) {
          console.log('stage 1')
          this.tempItemNameList = []
          for (let index = 0; index < this.parentOrderReqList.length; index++) {
            this.parentOrderReqList[index].isSelected = true
          }
        } else if (!this.orderReqSelectAll) {
          this.tempItemNameList = []
          for (let index = 0; index < this.parentOrderReqList.length; index++) {
            this.parentOrderReqList[index].isSelected = false
            this.tempItemNameList = []
          }
        }
      } else if (this.orderReqSearch !== '') {
        if (this.orderReqSelectAll) {
          console.log('stage 3')
          for (let index = 0; index < this.parentOrderReqList.length; index++) {
            if (this.parentOrderReqList[index].isSelected === false) {
              this.parentOrderReqList[index].isSelected = true
              this.tempItemNameList.push(this.parentOrderReqList[index])
            }
          }
        } else if (!this.orderReqSelectAll) {
          console.log('stage 4')
          this.tempItemNameList = []
          for (let J = 0; J < this.parentOrderReqList.length; J++) {
            this.parentOrderReqList[J].isSelected = false
          }
        }
      }
    },
    resetAll () {
      this.filterObject.startDate = this.defaultStartDate
      this.filterObject.endDate = this.defaultEndDate
      this.resetOrderReq()
      for (let i = 0; i < this.buttonFiltersArray.length; i++) {
        if (this.buttonFiltersArray[i].value === 'last30Days') {
          this.selectedValue = 'last30Days'
          this.buttonFiltersArray[i].selected = true
        } else {
          this.buttonFiltersArray[i].selected = false
        }
      }
      this.listing = []
    },
    resetOrderReq () {
      this.orderReqSearch = ''
      this.orderReqSelectAll = false
      this.filterObject.itemNameList = []
      this.tempItemNameList = []
      for (let index = 0; index < this.parentOrderReqList.length; index++) {
        this.parentOrderReqList[index].isSelected = false
      }
    },
    clearOrderReq () {
      this.orderReqSearch = ''
      // this.getItemList(false)
    },
    openCloseFilter () {
      this.isFilterOpen = !this.isFilterOpen
      console.log('openCloseFilter')
    },
    customDateListener (data) {
      console.log('datadatadatadatadatadata', data)
      this.showCustomDatepicker = false
      if (data !== null) {
        this.filterObject.startDate = data.startDate
        this.filterObject.endDate = data.endDate
        // this.getListing()
      }
    },
    selectData (data, index) {
      if (!this.orderReqSelectAll) {
        data.isSelected = !data.isSelected
        if (data.isSelected) {
          this.tempItemNameList.push(data)
        } else {
          const findex = this.tempItemNameList.findIndex(x => x.displayName === data.displayName)
          this.parentOrderReqList[index].isSelected = false
          this.tempItemNameList.splice(findex, 1)
        }
      } else {
        data.isSelected = !data.isSelected
        this.tempItemNameList = []
        for (let I = 0; I < this.parentOrderReqList.length; I++) {
          if (this.parentOrderReqList[I].isSelected === true) {
            this.tempItemNameList.push(this.parentOrderReqList[I])
          }
          this.orderReqSelectAll = false   
        }
      }
    },
    getDateTimeForFilterHandler (index, showCustomDatepicker) {
      this.selectedValue = this.buttonFiltersArray[index].value
      console.log('showCustomDatepicker', index, showCustomDatepicker)
      for (let i = 0; i < this.buttonFiltersArray.length; i++) {
        if (i !== index) {
          this.buttonFiltersArray[i].selected = false
        } else {
          this.buttonFiltersArray[i].selected = true
        }
      }
      if (this.buttonFiltersArray[index].value !== 'today' && this.buttonFiltersArray[index].value !== 'custom') {
        getDayAccordingToLimits(this.buttonFiltersArray[index].value).then(val => {
          console.log('val123123', val)
          this.filterObject.startDate = val.startDate
          this.filterObject.endDate = val.endDate
          // this.getListing()
        })
      } else {
        let date = new Date(new Date().setHours(0, 0, 0, 0)).toUTCString()
        if (this.buttonFiltersArray[index].value === 'today') {
          this.filterObject.startDate = date
          this.filterObject.endDate = date
          // this.getListing()
        } else {
          this.showCustomDatepicker = showCustomDatepicker
        }
      }
    },
    applyFilter() {
      this.getListing()
    },
    apiCaller () {
      this.isRefresh = false
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
      API.requiredDateData(
        response => {
          this.requiredDataListing = response.Data === null ? [] : response.Data
          console.log('this.requiredDataListing', this.requiredDataListing)
          this.filterObject.minDate = this.requiredDataListing.minDate
          this.filterObject.maxDate = this.requiredDataListing.maxDate
          this.isRefresh = true
          this.getItemList()
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
        },
        error => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getItemList () {
      this.isRefresh = false
      this.isOrderReqLoading = true
      API.ExtremeDogDoorStateList(
        this.orderReqSearch,
        response => {
          this.parentOrderReqList = response.Data === null ? [] : response.Data
          this.isRefresh = true
          if (this.tempItemNameList.length > 0 && this.filterObject.itemNameList.length > 0) {
            for (let I = 0; I < this.parentOrderReqList.length; I++) {
              for (let J = 0; J < this.tempItemNameList.length; J++) {
                if (this.tempItemNameList[J].displayName === this.parentOrderReqList[I].displayName) {
                  this.parentOrderReqList[I].isSelected = true
                }
              }
            }
          }
          this.isOrderReqLoading = false
        },
        error => {
          this.isOrderReqLoading = false
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getListing () {
      this.stateArray = dataBase
      this.tempData = []
      this.listing = []
      this.ExtFListingObj = []
      this.firstLoading = false
      let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      this.searchLoader = true
      if (this.filterObject.itemNameList.length === 0) {
        this.filterObject.itemNameList = []
      }
      API.ExtremeDogDoorGeographyList(
        utcSTART,
        utcEND,
        this.filterObject.itemNameList,
        response => {
          this.ExtFListingObj = response.Data === null ? [] : response.Data
          this.tempData = JSON.parse(JSON.stringify(this.stateArray))
          if (this.ExtFListingObj.length > 0) {
            for (let I = 0; I < this.ExtFListingObj.length; I++) {
              for (let J = 0; J < this.tempData.features.length; J++) {
                if (this.ExtFListingObj[I].orderShippingState === this.tempData.features[J].properties.inShort) {
                  this.tempData.features[J].properties.value = this.ExtFListingObj[I].orderTotalValueByState
                  this.tempData.features[J].properties.color = this.getColor(this.ExtFListingObj[I].orderTotalValueByState)
                  this.tempData.features[J].properties.totalOrder = this.ExtFListingObj[I].countOfOrders
                }
              }
            }
            let temp = []
            for (let index = 0; index < this.tempData.features.length; index++) {
              if (this.tempData.features[index].properties.value !== 0) {
                temp.push(this.tempData.features[index])
              }
            }
            let Obj = {
              "type": "FeatureCollection",
              "features": []
            }
            Obj.features = temp
            this.listing.push({
              data: Obj
            })
          } else {
            console.log('else condition')
            this.listing = []
            setTimeout(function () { window.dispatchEvent(new Event('resize')) }, 250)
          }
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.searchLoader = false
          this.firstLoading = true
          setTimeout(function () { window.dispatchEvent(new Event('resize')) }, 250)
        },
        error => {
          this.resetPaginationValues = false
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
  },
  destroyed() {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>

.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.loader2 {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1f48c2;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1.2s linear infinite;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}

</style>
